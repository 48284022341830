@charset "UTF-8";

@font-face {
   font-family: "icon";
   src: url("fonts/icon.eot");
   src: url("fonts/icon.eot?#iefix") format("embedded-opentype"), url("fonts/icon.woff") format("woff"),
      url("fonts/icon.ttf") format("truetype"), url("fonts/icon.svg#icon") format("svg");
   font-weight: normal;
   font-style: normal;
}

[data-icon]:before {
   font-family: "icon", sans-serif !important;
   content: attr(data-icon);
   font-style: normal !important;
   font-weight: normal !important;
   font-variant: normal !important;
   text-transform: none !important;
   speak: none;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
   font-family: "icon", sans-serif !important;
   font-style: normal !important;
   font-weight: normal !important;
   font-variant: normal !important;
   text-transform: none !important;
   speak: none;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.icon-help:before {
   content: "\e000";
}
.icon-ia:before {
   content: "\e001";
}
.icon-records:before {
   content: "\e002";
}
.icon-request:before {
   content: "\e003";
}
.icon-user:before {
   content: "\e004";
}
.icon-info:before {
   content: "\e005";
}
.icon-tag:before {
   content: "\e006";
}
.icon-gears:before {
   content: "\e007";
}
.icon-activity:before {
   content: "\e008";
}
.icon-caret-thin-right:before {
   content: "\e009";
}
.icon-caret-thin-left:before {
   content: "\e00a";
}
